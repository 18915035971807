import AOS from "aos";
import "aos/dist/aos.css";
import {useEffect} from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import {Index} from "./pages";
import {About} from "./pages/About";
import {Register} from "./pages/Register";
import Forgot from "./pages/Forgot";

export default function App() {
   useEffect(() => {
    AOS.init();
    AOS.refresh();
   })
  return (
    <Router>
        <Routes>
            <Route path="/" element={<Index/>} />
            <Route path="/about" element={<About/>} />
            <Route path={"/register"} element={<Register/>} />
            <Route path={"/forgot"} element={<Forgot/>} />
        </Routes>
    </Router>
  )
}