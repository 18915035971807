import { Button } from "./ui/button"


export default function Header() {
  return (
      <header className="bg-white dark:bg-gray-900">
          <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 items-center justify-between">
                  <div className="md:flex md:items-center md:gap-12">
                      <a className="block text-teal-600 dark:text-teal-600" href="/">
                          <span className="sr-only">Home</span>
                            <img src="/footer_logo.png" alt="exotic tale" width={100} height={80}/>
                      </a>
                  </div>

                  <div className="flex items-center gap-4">
                      <div className="sm:flex sm:gap-4">
                          <Button variant="outline">
                                <a  href="https://drive.google.com/file/d/1SDpy-JiUgjSy4ocdWymLm1UYw-Vs84nu/view?usp=sharing"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Play Now
                            </a>
                          </Button>


                          <div className="hidden sm:flex">
                              <a
                                  className="rounded-md bg-gray-100 px-5 py-2.5 text-sm font-medium text-teal-600 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
                                  href="/register"
                              >
                                  Register
                              </a>
                          </div>
                      </div>

                      <div className="block md:hidden">
                          <button
                              className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75 dark:bg-gray-800 dark:text-white dark:hover:text-white/75"
                          >
                              <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  className="h-5 w-5"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  stroke-width="2"
                              >
                                  <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16"/>
                              </svg>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </header>
  );
}