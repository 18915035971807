export default function Footer() {
    return (
        <footer className="bg-gray-50 dark:bg-gray-900">
            <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="flex justify-center text-teal-600 dark:text-teal-300 sm:justify-start">
                        <img src="/footer_logo.png" alt="exotic tale" width={100} height={80}/>
                    </div>

                    <p className="mt-4 text-center text-sm text-gray-500 dark:text-gray-400 lg:mt-0 lg:text-right">
                        Exotic Tale Copyright &copy; 2024. All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    )
}