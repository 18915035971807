import { Label } from "../components/ui/label"
import { Input } from "../components/ui/input"
import { Button } from "../components/ui/button"

export default function Forgot() {
    return (
        <div className="flex min-h-[100dvh] flex-col items-center justify-center bg-background px-4 py-12 sm:px-6 lg:px-8">
            <div className="mx-auto max-w-md text-center">
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">Forgot your password?</h1>
                <p className="mt-4 text-muted-foreground">
                    Enter your email below and we'll send you a link to reset your password.
                </p>
                <form className="mt-6 space-y-4">
                    <div>
                        <Label htmlFor="email" className="sr-only">
                            Email
                        </Label>
                        <Input id="email" type="email" placeholder="Enter your email" required />
                    </div>
                    <Button type="submit" disabled={true} className="w-full">
                        Reset Password
                    </Button>
                </form>
            </div>
        </div>
    )
}