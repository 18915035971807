import {Label} from "../components/ui/label"
import {Input} from "../components/ui/input"
import {Checkbox} from "../components/ui/checkbox"
import TermsOfService from "../components/TermsOfService";
import {Button} from "../components/ui/button"
import {useState} from "react";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios  from "axios";

const MySwal = withReactContent(Swal)

export const Register = () => {
    const [error, setError] = useState(null)
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    const handlerLambdaSubmit = async (event) => {
        event.preventDefault();
        const form = new FormData(event.target);
        const username = form.get("username");
        const email = form.get("email");
        const password = form.get("password");
        const confirmPassword = form.get("repeated");
        const terms = form.get("check");
        let payload = {
            username: username,
            email: email,
            password: password,
            repeated: confirmPassword,
            check: !!terms.match("on")
        }

        if (password !== confirmPassword) {
            alert("Passwords do not match.");
            return;
        }
        if (!terms) {
            alert("You must agree to the terms of service.");
            return;
        }

        try {
            setLoading(true);
            const response = await axios({
                method: 'post',
                url: 'https://mizkijrxmf.execute-api.us-east-1.amazonaws.com/',
                withCredentials: false,
                data: payload
            })

            setError(false);
            setMessage(response.data.message);
            setLoading(false);
            MySwal.fire({
                title: 'Success!',
                text: 'You have successfully registered. Please login and enjoy the game.',
                icon: 'success',
                confirmButtonText: 'Continue'
            }).then((result) => {
                if (result.isConfirmed) {
                    window.location.href = "/";
                }
            })

        }catch (e) {
            setError(true);
            setMessage(e.response.data.message);
            setLoading(false);
        }
    }


    return (
        <div className="flex min-h-screen items-center justify-center  bg-cover bg-center">
            <div className="mx-4 w-full max-w-md rounded-lg bg-background p-6 shadow-lg md:p-8">
                <div className="space-y-4">
                    <div className="text-center">
                        <h1 className="text-3xl font-bold">Welcome to Exotic Tale</h1>
                        <p className="text-muted-foreground">Join the epic adventure of Priston Tale, a thrilling online
                            RPG.</p>
                    </div>

                    <form onSubmit={handlerLambdaSubmit} className="space-y-4">
                        <div className="space-y-2">
                            <Label htmlFor="username">Username</Label>
                            <Input id="username" name="username" placeholder="Enter your username" required/>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="email">Email</Label>
                            <Input id="email" type="email" name="email" placeholder="Enter your email" required/>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="password">Password</Label>
                            <Input id="password" type="password" name="password" placeholder="Enter your password"
                                   required/>
                        </div>
                        <div className="space-y-2">
                            <Label htmlFor="confirm-password">Confirm Password</Label>
                            <Input id="confirm-password" type="password" name="repeated"
                                   placeholder="Confirm your password" required/>
                        </div>
                        <div className="flex items-start gap-2">
                            <Checkbox id="terms" name="check" required/>
                            <Label htmlFor="terms" className="text-sm">
                                I agree to the{" "}
                                <TermsOfService/>
                            </Label>
                        </div>
                        {error && <p className="text-red-500">{message}</p>}
                        <Button type="submit" className="w-full" disabled={loading}>
                            {loading ? "Loading..." : "Register"}
                        </Button>
                    </form>
                    <div className="text-center">
                        <p className="text-muted-foreground">
                            <a href="/forgot" className="underline ml-3">Forgot your password?</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}