export default function Starter() {
    return (
    <section
        className="overflow-hidden bg-cover bg-center bg-no-repeat" style={Styles}
    >
        <div className="bg-black/25 p-8 md:p-12 lg:px-16 lg:py-24">
            <div className="text-center ltr:sm:text-left rtl:sm:text-right">
                <h2 className="text-2xl font-bold text-white sm:text-3xl md:text-5xl">Exotic Tale</h2>

                <p className="max-w-lg inline-block text-center text-white/90 md:mt-6 md:inline-block md:text-lg md:leading-relaxed">
                    Immerse yourself in the vast universe of Pristontale, a free-to-play MMORPG. Enjoy balanced gameplay with regular character adjustments and dive into epic PvP battles for unforgettable adventures. Challenge yourself in the pinnacle of MMORPG experiences!
                </p>

                <div className="mt-4 sm:mt-8">
                    <a
                        href="/register"
                        className="inline-block rounded-full bg-indigo-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring focus:ring-yellow-400"
                    >
                        Sign Up Today
                    </a>
                </div>
            </div>
        </div>
    </section>
  );
}

const Styles = {
    backgroundImage: `url(/temo.jpg)`,
    height: "400px"
}