/**
 * v0 by Vercel.
 * @see https://v0.dev/t/qaDv7A5A95I
 * Documentation: https://v0.dev/docs#integrating-generated-code-into-your-nextjs-app
 */
import { Dialog, DialogTrigger, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"

export default function TermsOfService() {
    return (
        <Dialog defaultOpen={false} >
            <DialogTrigger asChild>
                <button  className={"underline"}>Terms of Service</button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[600px]">
                <DialogHeader>
                    <DialogTitle>Terms of Service</DialogTitle>
                </DialogHeader>
                <div className="prose max-h-[400px] overflow-auto p-4">
                    <h2>1. Introduction</h2>
                    <p>
                        Welcome to our website and services. By accessing or using our website, mobile application, or any of our
                        services, you agree to be bound by these terms of service and our privacy policy.
                    </p>
                    <h2>2. User Accounts</h2>
                    <p>
                        To use our services, you may be required to create an account. You are responsible for maintaining the
                        confidentiality of your account and password, and for restricting access to your computer. You agree to
                        accept responsibility for all activities that occur under your account or password.
                    </p>
                    <h2>3. Intellectual Property</h2>
                    <p>
                        Our website and services contain proprietary content, information, and material that is protected by
                        applicable intellectual property and other laws. You may not modify, copy, distribute, transmit, display,
                        reproduce, or create derivative works from our content.
                    </p>
                    <h2>4. Termination</h2>
                    <p>
                        We reserve the right to suspend or terminate your access to our services at any time for any reason,
                        including if we reasonably believe you have violated these terms of service.
                    </p>
                    <h2>5. Limitation of Liability</h2>
                    <p>
                        In no event shall we be liable for any indirect, special, incidental, or consequential damages related to
                        your use of our services.
                    </p>
                </div>
                <DialogFooter>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}