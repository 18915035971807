export default function Discord() {
    return (
       <>
           <div data-aos="fade-up-left"
                className="hidden md:block lg:block fixed text-center rounded-l-lg w-40 h-32 top-24 right-0 dark:bg-slate-900">
                   <a data-aos="fade-left" className="block text-teal-600 dark:text-teal-600"
                      href="https://discord.gg/Sa8fykK8f5" >
                       <div className="dark:text-white flex flex-col justify-center items-center">
                           <div>
                               EPT Community
                           </div>
                           <img className="align-middle" width={80} height={80} src="/discord.gif" alt="discord"/>
                           <div className="dark:text-white">
                               Discord
                           </div>
                       </div>
                   </a>
           </div>
       </>
    )
}